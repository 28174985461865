:root {
  --primary: #18a587;
  --primary-light: #78d5c2;
  --primary-dark: #085940;

  --red: #f0142f;
  --yellow: #ffce00;
  --blue: #00a1dd;
  --orange: #f99600;
  --royal-blue: #6c4ded;
  --light-green: #21d59b;
  --light: #f7f7f7;

  --border-primary: #18a587;
  --border-light: #b5b5b5;
  --border-defualt: #707070;

  --text-primary: #18a587;
  --text-white: #fff;
  --text-default: #242424;
  --text-secondary: #525252;
}

.bg-blue {
  background-color: var(--blue);
}

.bg-red {
  background-color: var(--red);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-royal-blue {
  background-color: var(--royal-blue);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-light-green {
  background-color: var(--light-green);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-light);
}

::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-dark);
}

/* THEME */
body,
html,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  color: var(--text-default);
}
h1 {
  font-size: 97px;
  font-weight: 300;
}
h2 {
  font-size: 61px;
  font-weight: 300;
}
h3 {
  font-size: 48px;
  font-weight: 400;
}
h4 {
  font-size: 34px;
  font-weight: 600;
}
h5 {
  font-size: 24px;
  font-weight: 600;
}
h6 {
  font-size: 20px;
  font-weight: 600;
}


.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.subtitle-1 {
  font-size: 16px;
  font-weight: 600;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: 600;
}

.body-1 {
  font-size: 16px;
  font-weight: 400;
}

.body-2 {
  font-size: 14px;
  font-weight: 400;
}

.btn-text {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.caption {
  font-size: 12px;
  font-weight: 400;
}

.overline {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

.txt-primary {
  color: var(--text-primary);
}

.txt-primary-light {
  color: var(--primary-light);
}

.txt-primary-dark {
  color: var(--primary-dark);
}

.txt-secondary {
  color: var(--text-secondary);
}

.focus-none:focus {
  box-shadow: none;
}

.btn {
  height: 36px !important ;
}

.button-primary {
  background-color: var(--primary);
  border: 0;
  padding: auto 16px;
  color: white;
  height: 36px;
}

.button-parent {
  padding: auto 16px auto 12px;
  color: white;
  height: 36px;
}

.button-child {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.page-link:hover {
  color: var(--primary) !important;
}

.page-selected {
  background-color: #e9ecef;
}

.button-primary:hover {
  background-color: var(--primary-dark);
  color: white;
}

.button-primary-outline {
  border-color: var(--primary);
  background-color: white;
  padding: auto 16px;
  color: var(--primary);
  height: 36px;
}

.button-primary-outline:hover {
  color: #ffffff;
  background-color: var(--primary);
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem #d9f2ed !important;
}

.focus-primary:not(.collapsed) {
  background-color: white;
}

.button-primary:focus,
.button-primary:active {
  background-color: var(--primary-dark);
}

.spinner-border-lg {
  height: 4rem;
  width: 4rem;
}

.bg-main {
  background-color: #242424;
  color: #fff;
}

.bg-login {
  height: 100%;
  display: block;
  background-size: cover;
}

.auth-img {
  margin-top: 0;
}

.bg-body {
  background-color: #fafafa !important ;
}

.bg-primary {
  background-color: var(--primary) !important ;
  color: white;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.login-img {
  width: 100%;
}

.login-card {
  width: 550px;
  height: 100%;
}

.sidebar {
  z-index: 10;
}

.sidebar-icon {
  width: 60px;
}

.sidebar-row {
  border-left: 5px solid white;
}

.sidebar-description {
  width: 260px;
}

.sidebar-item-selected {
  background-color: #f3fbf9;
  color: var(--primary);
  border-left: 5px solid var(--primary-dark);
}

.back {
  padding-left: 5px;
}

.users-list {
  height: 90%;
}
.users-list-body {
  overflow-y: scroll;
  height: 95%;
}

.admin-users-fields {
  transition: background-color 0.2s ease-in;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.users-list-body > .admin-users-fields:hover {
  background-color: #f3fbf9;
  color: var(--primary);
  border-left: 5px solid var(--primary-dark);
}

.admin-users-field {
  width: 13%;
}
.admin-schools-field {
  width: 30%;
}
.admin-assessments-field {
  width: 20%;
}

.admin-classrooms-field {
  width: 15%;
}
.dashboard-margin {
  margin-left: 65px;
}

.admin-back {
  width: 40px;
  height: 40px;
  transition: color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
  border: 1px solid var(--primary);
}

.admin-back:hover {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.admin-assessments-type {
  height: 90px;
  width: 90px;
}

.assessments-type-selected {
  border: 2px solid var(--primary);
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: #d9f2ed;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #d9f2ed;
}

.form-select:disabled {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* Standard */
  background-color: transparent; /* Grey background to indicate it's disabled */
  border:transparent; /* Blue border to match input field */
  padding: 0rem; /* Padding to match input field */
  font-size: 1rem; /* Font size to match input field */
  line-height: 1.5; /* Line height to match input field */
  color: #495057; /* Text color to match input field */
  border-radius: 0.25rem; /* Rounded corners to match input field */
  display: block; /* Block display */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  background-image: none; /* Remove the background image (arrow) */
}

.add-item-btn {
  transition: color 0.3s ease, background-color 0.3s ease;
}
.add-item-btn:hover {
  color: white;
  background-color: var(--primary);
  cursor: pointer;
  border: 0;
}
.delete-item-btn {
  transition: color 0.3s ease, background-color 0.3s ease;
  border-color: var(--red) !important ;
  color: var(--red);
}
.delete-item-btn:hover {
  color: white;
  background-color: var(--red);
  cursor: pointer;
  border: 0;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

@media only screen and (max-width: 768px) {
  .admin-users-field {
    width: 28%;
  }
  .button-primary,
  .admin-users-field {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 500px) {
  .admin-assessments-field {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .login-card {
    width: 30%;
  }
  .bg-login {
    background-size: 80% 100%;
    background-repeat: no-repeat;
  }
  .auth-img {
    margin-top: 90px;
  }
}

.assessment-number {
  width: 3.1rem;
  height: 4.1rem;
  -moz-appearance: textfield;
  font-size: 2.7rem;
  text-align: center;
}

.react-contextmenu {
  z-index: 100;
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #d9d9d9;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu-wrapper {
  display: inline;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: inline;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;

  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  margin-left: 10px;
}
.react-contextmenu-item svg {
  font-size: 14px;
}

.incorrect {
  min-width: 30px;
  min-height: 30px;
  border: 1px solid red;
  background-color: #fbc4ca;
  color: red;
}

.self-correct {
  min-width: 30px;
  min-height: 30px;
  border: 1px solid var(--primary);
}

.skip {
  min-width: 30px;
  min-height: 30px;
  vertical-align: middle;
  border: 1px solid red;
  color: red;
}

.no-response {
  min-width: 30px;
  min-height: 30px;
  border: 1px solid red;
  color: red;
}

.time-out {
  min-width: 30px;
  min-height: 30px;
}

.button-non {
  border: 0;
  background: none;
}

.button-non,
.incorrect,
.no-response,
.self-correct,
.skip,
.incorrect {
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.form-silly {
  width: 40px;
  -moz-appearance: textfield;
}

.dashboard-grade {
  width: 33.33%;
}

/* Toogle Customize */
.toggle-container {
  width: 100%;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height:35px;
  position: relative;
}

.dialog-button {
  line-height: 100%;
  cursor: pointer;
  background-color: #002b49;
  color: white;
  padding: 8px 12px;
  border-radius: 18px;
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #707070;
  left:50%;
}

.custom-progress-bar .progress {
  border-radius: 10px; 
  border: 1px solid black;
  height: 20px; 
}



